export default context => {
  return {
    rulesEmail: [
      value => !!value || context.$t('rules.required'),
      v =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        context.$t('rules.email'),
    ],
    rules: [value => !!value || context.$t('rules.required')],
  };
};
