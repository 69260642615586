<template>
  <PetitionLayout
    :previous="previousPage"
    :next="nextPage"
    :title="$t('advisorForm.tellUsAFewThingsAboutYourself')"
  >
    <v-form ref="form" v-model="valid" class="form-login px-3" lazy-validation>
      <v-row class="align-center">
        <v-col class="flex-row align-center pt-8 pb-2" cols="12">
          <div class="font-size-text line-height-title purple-dark--text font-weight-bold">
            {{ $t('advisorForm.whoWillBeTheContractingParty') }}
          </div>
        </v-col>
      </v-row>

      <v-row class="align-center bottom-border">
        <v-col class="d-flex align-center" cols="12">
          <v-select
            class="card-text-field-border hide-input-selection"
            ref="emb"
            height="54"
            :items="selectItems"
            item-text="text"
            v-model="petition.resort.contracting"
            item-value="text"
            :rules="rules.rules"
            dense
            outlined
            return-object
            flat
            background-color="white"
            ><template v-slot:selection="data">
              <div class="selection-data">
                {{ data.item.text }}&nbsp;
                <span
                  class="ml-0"
                  v-if="data.item.text != $t('advisorForm.other')"
                  >{{ data.item.surname }}</span
                >
              </div>
            </template>
            <template v-slot:item="data">
              {{ data.item.text }}&nbsp;
              <span
                class="ml-1"
                v-if="data.item.text != $t('advisorForm.other')"
                >{{ data.item.surname }}</span
              >
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row
        v-if="petition.resort.contracting.text != $t('advisorForm.other')"
        class="align-center"
      >
        <v-col class="flex-row align-center pt-8 pb-md-3 pb-2" cols="12">
          <v-text-field
            :label="$t('advisorForm.email')"
            height="54"
            class="card-text-field-border label-class"
            ref="emb"
            :rules="rules.rulesEmail"
            v-model="petition.resort.contracting_email"
            dense
            outlined
            flat
            background-color="white"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <div v-if="petition.resort.contracting.text == $t('advisorForm.other')">
      <advisor-data ref="other" />
    </div>
    <v-snackbar
      timeout="99999"
      rounded="lg"
      elevation="0"
      top
      color="#FFF0EF"
      class="error-snackbar"
      light
      v-model="snackbar"
    >
      <v-icon color="error">mdi-information-outline</v-icon
      ><span class="error--text ml-2"> {{ snackbarText }}</span>
    </v-snackbar>
  </PetitionLayout>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import PetitionLayout from '@/components/layouts/PetitionLayout.vue';
import AdvisorData from '@/views/AdvisorData.vue';
import rules from '@/helpers/validation-rules-advisor';
import ownerRules from '@/helpers/validation-rules-owner';

export default {
  name: 'AboutAdvisor',
  data() {
    return {
      snackbarText: '',
      snackbar: false,
      valid: true,
      email: '',
      rules: {},
      ownerRules: {},
    };
  },
  computed: {
    ...mapGetters(['petition']),
    selectItems() {
      return [
        ...this.petition.resort.owners
          .filter((_, index) => index < this.petition.resort.ownerNumber)
          .map((owner) => {
            owner.text = owner.name;
            return owner;
          }),
        {
          text: this.$t('advisorForm.other'),
        },
      ];
    },
  },
  components: {
    PetitionLayout,
    AdvisorData,
  },

  watch: {
    petition: {
      deep: true,
      handler() {
        this.rules = {};
      },
    },
  },
  methods: {
    ...mapMutations([
      'setPrice',
      'setLoading',
      'moveToNextStep',
      'moveToPreviousStep',
    ]),

    previousPage() {
      this.$router.back();
    },
    nextPage() {
      this.rules = rules(this);
      this.$nextTick(() => {
        if (!this.$refs.form.validate()) {
          this.snackbarText = this.$t('advisorForm.pleaseFillOutTheForm');
          return (this.snackbar = true);
        }
        if (
          this.petition.resort.contracting.text == this.$t('advisorForm.other')
        ) {
          this.$refs.other.rules = ownerRules(this.$refs.other);
          this.$nextTick(() => {
            if (!this.$refs.other.$refs.form.validate()) {
              this.snackbarText = this.$t('advisorForm.pleaseFillOutTheForm');
              this.snackbar = true;
              return;
            }

            this.setLoading(true);

            this.$axios
              .post(`get-quotation-service`, this.petition)
              .then(({ data }) => {
                if (data.name == 'Error') {
                  this.snackbarText = this.$t('advisorForm.errorThereWasAProblemWithTheServer');
                  this.setLoading(false);
                  return (this.snackbar = true);
                }
                this.$axios
                  .post(`search-customer`, this.petition)
                  .then(({ data }) => {
                    this.setLoading(false);
                    this.moveToNextStep();
                    this.$router.push({ name: 'Checkout' });
                    this.petition.contractingDTO = data.contractingDTO;
                    this.petition.ownersDTO = data.ownersDTO;
                  })
                  .catch((err) => {
                    this.setLoading(false);
                    // eslint-disable-next-line
                    console.log(err);
                  });

                this.setPrice(data);
              })
              .catch((err) => {
                // eslint-disable-next-line
                console.log(err);
                this.setLoading(false);
              });
          });
          return;
        }
        this.setLoading(true);

        this.$axios
          .post(`get-quotation-service`, this.petition)
          .then(({ data }) => {
            if (data.name == 'Error') {
              this.snackbarText = this.$t('advisorForm.errorThereWasAProblemWithTheServer');
              this.setLoading(false);
              return (this.snackbar = true);
            }
            this.$axios
              .post(`search-customer`, this.petition)
              .then(({ data }) => {
                this.setLoading(false);
                this.moveToNextStep();
                this.$router.push({ name: 'Checkout' });
                this.petition.contractingDTO = data.contractingDTO;
                this.petition.ownersDTO = data.ownersDTO;
              })
              .catch((err) => {
                this.setLoading(false);
                // eslint-disable-next-line
                console.log(err);
              });

            this.setPrice(data);
          })
          .catch((err) => {
            // eslint-disable-next-line
            console.log(err);
            this.setLoading(false);
          });
      });
    },
  },

  mounted() {
    if (!this.petition.resort.contracting.text) {
      const [selectedUser] = this.selectItems;
      this.petition.resort.contracting = selectedUser;
    }
  },
};
</script>
